import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { GETREPORTV2 } from "../../../Graphql/QueriesNew";
import MyLoader from "../../MiroComponents/PreLoader";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";
import {
  ImplementedStatusOptions,
  ReportFilterValues,
} from "../../../Libs/ReportFiltersValues";
import SmartContainer from "../../MiroComponents/SmartContainer/SmartContainer";
import { SmartHeading } from "../../MiroComponents/SmartHeading/SmartHeading";
import { SmartCard } from "../../MiroComponents/SmartCard/SmartCard";
import { SmartInput } from "../../MiroComponents/SmartInput/SmartInput";
import { SmartSelector } from "../../MiroComponents/SmartSelector/SmartSelector";
import { useEffect, useRef, useState } from "react";
import { Button } from "../../MiroComponents/Button/Button";
import { CrossIcon2 } from "../../Shared/Icons";
import { toast } from "react-toastify";
import { CREATEUPDATEMANUALREPORT } from "../../../Graphql/Mutations";
import MyRoutes from "../../../Routes";

const initialtFactor = {
  "Change in CR": null,
  "Change in AOV": null,
  "Change in RPU": null,
};
const defaultFactorKey = "Change in CR";
const conclusionfactors = ["Change in CR", "Change in AOV", "Change in RPU"];
const btns = ["Winner", "Neutral", "No Uplift"];
export default function CreateManualReport() {
  const { reportId } = useParams();
  const navigate = useNavigate();
  const [conclusionFactor, setConclusionFactor] = useState(initialtFactor);
  const [segmentName, setSegmentName] = useState({
    label: "All Users",
    value: "All Users",
  });
  const [createupdatemanualreport] = useMutation(CREATEUPDATEMANUALREPORT);
  const [variants, setVariants] = useState([]);
  const [implementedStatus, setImplementedStatus] = useState(null);
  const [evaluationVariant, setEvaluationVariant] = useState(null);
  const [clientLearnings, setClientLearnings] = useState([]);
  const learningsInputRef = useRef(null);

  const { data, loading, error } = useQuery(GETREPORTV2, {
    variables: {
      getReportV2Id: reportId,
    },
  });

  useEffect(() => {
    if (data?.getReportV2[0]?.learnings) {
      setClientLearnings(data?.getReportV2[0]?.learnings);
    }
    return () => {};
  }, [data]);

  if (loading) return <MyLoader />;
  if (error || !data?.getReportV2)
    return (
      <NoDataFound title="There was an error" description={error?.message} />
    );
  const report = data?.getReportV2[0];
  const AllOption = [{ label: "All Users", values: ["All Users"] }];
  const groupedOptions = [...AllOption, ...ReportFilterValues].map((filter) => {
    return {
      label: filter.label,
      options: filter.values.map((op) => {
        return { label: op, value: op };
      }),
    };
  });

  const handleVariantInputChange = (e, i, key) => {
    const newVariants = [...variants];
    if (
      key === "audienceName" &&
      newVariants.find((v) => v.audienceName === e.target.value)
    ) {
      toast.error("Audience Name already exists");
      return;
    }
    if (key !== "audienceName" && isNaN(Number(e.target.value))) {
      toast.error("Please enter a valid number");
      e.target.value = "";
      return;
    }
    newVariants[i][key] =
      key !== "audienceName" ? Number(e.target.value) : e.target.value;
    setVariants(newVariants);
  };

  if (report?.reportDetails && report?.segmentName?.toLowerCase() !== "no data")
    return (
      <div
        onClick={(e) => {
          navigate(MyRoutes.clientreports, {
            state: { reportId },
          });
        }}
      >
        <NoDataFound
          title={
            report?.reportDetails
              ? `Report Details already exists!`
              : `Operation not permitted!`
          }
          description={
            report?.reportDetails
              ? `Report already exists. If you intent to change the report data then delete report and evaulate test again.`
              : `Reports with automated data can not be changed! Please ask admin to delete the current report and create new one if needed.`
          }
        />
      </div>
    );
  return (
    <div>
      <SmartContainer className="p-24-top p-24-bottom">
        <h2>Re-Evaluate Report : {report?.test?.name}</h2>
        <SmartCard bodyClass="projected-values">
          <form
            className="full-width"
            onSubmit={async (e) => {
              e.preventDefault();
              const { notificationText } = e.target;
              // if (
              //   !conclusionFactor[defaultFactorKey] ||
              //   !implementedStatus ||
              //   !evaluationVariant
              // ) {
              //   toast.error("Please fill all the fields");
              //   return;
              // }
              const checkVariantData = variants?.every((v) =>
                Object.values(v).every((v) => v)
              );
              if (!checkVariantData) {
                toast.error("Please fill all the fields in variants data!");
                return;
              }
              const reportPayload = {
                conclusion: conclusionFactor[defaultFactorKey]?.toLowerCase(),
                learnings: clientLearnings,
                segmentName: segmentName?.value,
                variant: evaluationVariant?.value,
                reportDetails: {
                  conclusionFactor: defaultFactorKey?.toLowerCase(),
                  conclusionFactorStatusAll: conclusionFactor,
                  notificationText: notificationText?.value || "",
                  implementedStatus: implementedStatus?.value,
                  evaluationType: "manual",
                  manualData: variants,
                },
              };
              try {
                await createupdatemanualreport({
                  variables: {
                    reportPayload,
                    reportId: reportId,
                  },
                });
                // refetch();
                toast.success("Report Updated Successfully");
                navigate(MyRoutes.clientreports, {
                  state: { reportId: reportId },
                });
              } catch (error) {
                toast.error("Error while updating report");
              }
            }}
          >
            <SmartSelector
              options={groupedOptions}
              label="Choose Segment"
              value={segmentName}
              onChange={(value) => {
                setSegmentName(value);
              }}
            />

            <div className="default-border default-padding default-margin-bottom">
              {conclusionfactors.map((factor, i) => {
                return (
                  <div className="smart-input" key={`factor-${i}`}>
                    <label>{factor}</label>
                    <div className=" d-flex default-column-gap">
                      {btns.map((btn, j) => (
                        <div className="" key={j}>
                          <Button
                            type="button"
                            className={`smart-pill small ${
                              conclusionFactor[factor] === btn ? `active` : ``
                            }`}
                            text={btn}
                            onClick={(e) => {
                              setConclusionFactor({
                                ...conclusionFactor,
                                [factor]: btn,
                              });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>

            {conclusionFactor[defaultFactorKey] && (
              <SmartInput
                label="Message"
                placeholder="Enter Message"
                name="notificationText"
              />
            )}

            <SmartSelector
              options={ImplementedStatusOptions}
              label="Choose Implementation Status"
              value={implementedStatus}
              onChange={(value) => {
                setImplementedStatus(value);
              }}
            />
            <div className="default-padding default-border default-margin-bottom">
              <SmartInput
                label="Add Test Learnings"
                placeholder="Add new learning"
                name="learning"
                ref={learningsInputRef}
                // tip="Add learnings from the test"
              />
              <div className="d-flex justify-content-end">
                <Button
                  type="button"
                  onClick={(e) => {
                    const learningValue =
                      learningsInputRef.current?.value?.trim();
                    if (learningValue) {
                      setClientLearnings([...clientLearnings, learningValue]);
                      learningsInputRef.current.value = "";
                    }
                  }}
                  className="smart-button small active default-margin-bottom"
                  text="Add Learning"
                ></Button>
              </div>

              <div className="default-column-gap">
                {clientLearnings.map((learning, i) => {
                  return (
                    <div className="smart-input" key={`learning-${i}`}>
                      <label>
                        {learning}{" "}
                        <Button
                          type="button"
                          text={"Remove"}
                          icon={<CrossIcon2 fill="#fff" />}
                          className="smart-pill xmall"
                          style={{
                            backgroundColor: "red",
                            borderColor: "red",
                            marginLeft: "2px",
                          }}
                          onClick={(e) => {
                            setClientLearnings(
                              clientLearnings.filter((_, index) => index !== i)
                            );
                          }}
                        />
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="default-padding default-border default-margin-bottom">
              <div className="d-flex align-center justify-content-between">
                <h3> Add Variants</h3>
                <Button
                  className="smart-button small active"
                  text="Add New Variant"
                  type="button"
                  onClick={(e) => {
                    setVariants([
                      ...variants,
                      {
                        audienceName:
                          variants?.length > 0
                            ? `variation_${variants?.length}`
                            : "reference",
                        totalUsers: null,
                        sessions: null,
                        transactions: null,
                        purchasers: null,
                        purchaseRevenue: null,
                        stddev_rpu: null,
                        stddev_aov: null,
                      },
                    ]);
                  }}
                ></Button>
              </div>
              <div>
                {variants?.length === 0 ? (
                  <NoDataFound
                    title="Add at least one variant"
                    description="You need to add variants and the data in order to create report."
                  />
                ) : (
                  <div>
                    {variants.map((variant, i) => {
                      return (
                        <div
                          className="default-border default-padding default-margin-bottom default-margin-top d-flex flex-wrap default-column-gap default-row-gap justify-content-between"
                          key={`variant-${i}`}
                        >
                          <SmartInput
                            label="Audience Name"
                            type="text"
                            placeholder="Enter Audience Name"
                            name="audienceName"
                            value={variant.audienceName}
                            // readOnly
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "audienceName")
                            }
                          />
                          <SmartInput
                            label="Total Users"
                            placeholder="Enter Total Users"
                            name="totalUsers"
                            value={variant.totalUsers}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "totalUsers")
                            }
                          />
                          <SmartInput
                            label="Sessions"
                            placeholder="Enter Sessions"
                            name="sessions"
                            value={variant.sessions}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "sessions")
                            }
                          />
                          <SmartInput
                            label="Transactions"
                            placeholder="Enter Transactions"
                            name="transactions"
                            value={variant.transactions}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "transactions")
                            }
                          />
                          <SmartInput
                            label="Purchasers"
                            placeholder="Enter Purchasers"
                            name="purchasers"
                            value={variant.purchasers}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "purchasers")
                            }
                          />
                          <SmartInput
                            label="Purchase Revenue"
                            placeholder="Enter Purchase Revenue"
                            name="purchaseRevenue"
                            value={variant.purchaseRevenue}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "purchaseRevenue")
                            }
                          />
                          <SmartInput
                            label="Stddev RPU"
                            placeholder="Enter Stddev RPU"
                            name="stddev_rpu"
                            value={variant.stddev_rpu}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "stddev_rpu")
                            }
                          />
                          <SmartInput
                            label="Stddev AOV"
                            placeholder="Enter Stddev AOV"
                            name="stddev_aov"
                            value={variant.stddev_aov}
                            onChange={(e) =>
                              handleVariantInputChange(e, i, "stddev_aov")
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {variants?.length > 0 && (
                <SmartSelector
                  options={variants?.map((variant, i) => {
                    return {
                      label: variant.audienceName,
                      value: variant.audienceName,
                    };
                  })}
                  label="Choose Evaluating Variant"
                  value={evaluationVariant}
                  onChange={(value) => {
                    setEvaluationVariant(value);
                  }}
                />
              )}
            </div>
            <div
              className="
                d-flex
                justify-content-end
                default-margin-top
                default-margin-bottom

              "
            >
              <Button
                className="smart-button big active"
                text="Create Report"
                type="submit"
                // onClick={handleSubmit}
              />
            </div>
          </form>
        </SmartCard>
      </SmartContainer>
    </div>
  );
}

// PAYLOAD
