import { useLazyQuery, useQuery } from "@apollo/client";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import moment from "moment";
import { GETCLIENTTESTS } from "../../Graphql/Queries";
import { testName } from "../../Libs/Utilities";
import { ActiveTestIcon } from "../Shared/Icons";
import React from "react";
import { Button } from "../MiroComponents/Button/Button";

export function TestsList({
  client,
  viewId,
  selectedTest,
  setSelectedTest,
  setSelectionRange,
  setSelectedTestId,
  setReportDetails,
  platform,
  setCurrentReportType = () => void 0,
}) {
  const { data, loading } = useQuery(GETCLIENTTESTS, {
    fetchPolicy: "no-cache",
    variables: {
      client,
      viewId: platform !== "BQ" ? viewId : "all",
      status: "live",
    },
  });

  // console.log(data, client, viewId);

  if (loading) return "Loading";
  return (
    <div className="experiment-list">
      <Splide
        aria-label="My Favorite Images"
        options={{
          type: "slide",
          perPage: 3,
          pagination: false,
          arrows: true,
          gap: "10px",
          breakpoints: {
            1279: {
              perPage: 2,
              pagination: true,
              arrows: false,
            },
            // 767: {
            //   pagination: true,
            //   // arrows: false,
            // },
          },
        }}
      >
        {data?.getClientTests?.tests?.map((test, i) => (
          <SplideSlide key={i}>
            <Button
              type="button"
              key={i}
              className={`smart-button full-width big elipses ${
                selectedTest?._id === test?._id ? `active` : ``
              }`}
              icon={<ActiveTestIcon />}
              onClick={(e) => {
                const date = test?.livedate ?? new Date();
                const getstartdate = moment(date).toDate();
                setSelectionRange([
                  {
                    endDate: new Date(),
                    startDate: getstartdate,
                    key: "selection",
                  },
                ]);
                setSelectedTest(test);
                if (setSelectedTestId) {
                  setSelectedTestId(test?._id);
                }
                if (setReportDetails) {
                  setReportDetails(null);
                }
                if (setCurrentReportType) {
                  setCurrentReportType(null);
                }
              }}
            >
              <span>{testName(test?.name)}</span>
            </Button>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

export const LiveTestsList = React.memo(TestsList);
