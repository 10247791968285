import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import MyRoutes from "../../Routes";
import useStore from "../../Store";
import { routes } from "../../Libs/RoutesManager";
import { DropIcon, Logo, LogoMobile, MinMaxIcon } from "../Shared/Icons";

function SideBarNew({ myProfile, setSideBar, vw }) {
  const rolesCheck = myProfile?.role?.map((rl) => rl.name);
  const { toggleForm, setCrumb, toggleEditForm } = useStore();

  useEffect(() => {
    const allRoutesCheck = routes?.reduce((acc, item) => {
      if (item?.rolesAllowed?.some((rl) => rolesCheck?.includes(rl))) {
        if (item?.subItem) {
          item?.subItem?.forEach((subItem) => {
            acc.push({
              name: subItem.name,
              route: subItem.route,
              rolesAllowed: subItem.rolesAllowed,
            });
          });
        }
        acc.push({
          name: item.name,
          route: item.route,
          rolesAllowed: item.rolesAllowed,
        });
      }
      return acc;
    }, []);
    let currentRoute = allRoutesCheck?.find(
      (item) =>
        item.route === window.location.pathname ||
        item.route?.subItem?.some(
          (item) => item.route === window.location.pathname
        )
    );
    if (window.location.pathname.indexOf("/manage-client-retentions") > -1) {
      currentRoute = {
        name: "Manage Client Retentions",
        route: MyRoutes.manageclientretentions,
        rolesAllowed: ["ADMIN", "OPTIMIZER"],
      };
    }
    // if (!currentRoute?.rolesAllowed?.some((rl) => rolesCheck?.includes(rl))) {
    //   localStorage.removeItem("codebase_token");
    //   window.location.href = "/auth/login";
    // }
  }, []);

  return (
    <div className="sidebar-inner">
      <ul className="sidebar-content">
        <li className="sidebar-list logo">
          <Logo />
        </li>
        <li className="sidebar-list logo mobile">
          <LogoMobile />
        </li>
        {routes?.map((item, i) => {
          if (
            !item?.settingsBar &&
            item?.rolesAllowed?.some((rl) => rolesCheck?.includes(rl))
          ) {
            return (
              <li
                className={`sidebar-list ${item?.subItem ? `arrowdown` : ``}`}
                key={i}
              >
                <NavLink
                  className={
                    item?.subItem &&
                    (item?.route === window.location.pathname ||
                      item?.subItem?.some(
                        (item) => item.route === window.location.pathname
                      ))
                      ? "dropactive"
                      : ``
                  }
                  onClick={(_) => {
                    toggleForm(false);
                    toggleEditForm(false);
                    setCrumb(item.name);
                    if (vw < 1024) {
                      setSideBar(true);
                    }
                  }}
                  to={item?.route}
                >
                  <span>
                    <span className="icon">{item?.icon}</span>
                    <span className="sidebar-text"> {item?.name} </span>
                  </span>

                  {item?.subItem ? (
                    <>
                      <span className="dropicon">
                        <DropIcon />
                      </span>
                    </>
                  ) : (
                    <> </>
                  )}
                </NavLink>
                {item?.subItem && (
                  <div id="subitem" className="submenu">
                    {item?.subItem.map((si, i) => {
                      if (
                        si?.rolesAllowed?.some(
                          (r) => rolesCheck.indexOf(r) > -1
                        )
                      ) {
                        return (
                          <NavLink
                            to={si?.route}
                            key={i}
                            onClick={(_) => {
                              toggleForm(false);
                              toggleEditForm(false);
                              setCrumb(si.name);
                              if (vw < 1024) {
                                setSideBar(true);
                              }
                            }}
                          >
                            {si?.name}
                          </NavLink>
                        );
                      }
                    })}
                  </div>
                )}
              </li>
            );
          }
        })}
      </ul>
      <ul className="minmaxlist">
        <li className="sidebar-list">
          <a onClick={(e) => setSideBar()}>
            <span>
              <span className="icon">
                <MinMaxIcon />
              </span>
              <span className="sidebar-text">Minimize</span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SideBarNew;
